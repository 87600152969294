<template>
  <div>
    <Member-add-new
      :is-add-new-member-sidebar-active.sync="isAddNewMemberSidebarActive"
      :gender-options="genderOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
      :center-options="centerOptions"
      @clicked="one"
    />
    <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end mb-1">
          <b-form-input
            v-model="searchString"
            ref="search"
            class="d-inline-block mr-1"
            :placeholder="$t('Search')+'...'"
          />
          <b-button
            variant="primary"
            @click="isAddNewMemberSidebarActive = true"
            v-if="$can('read', 'staff')"
          >
            <span class="text-nowrap"> {{$t('Add Member')}} </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      striped
      @row-selected="onRowSelected"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }} , Page {{currentPage}}
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="my-0"
          @change="handleChangePage"
        />
      </b-col>
    </b-row>
  </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BAvatar, BCardBody, BCard, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,
} from 'bootstrap-vue'
import MemberAddNew from './MemberListAddNew.vue'

export default {
  components: {
    MemberAddNew,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      test_string : null,
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'DELETED'
      },
      {
        1: 'light-success', 2: 'danger', 3: 'info'
      }],
      centerOptions: [],
      isAddNewMemberSidebarActive: false,
      searchString: null,
      rollback : false,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'member_code', label: this.$t('Member Code') , sortable: true},
       { key: 'member_name', label: this.$t('Name') },
        { key: 'mobile', label: this.$t('Mobile') },
         { key: 'email', label: this.$t('E-Mail') },
           { key: 'status_number', label: this.$t('Status')}
         ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Active', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  mounted() {
   this.$refs.search.$el.focus();
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.$store.commit('member_search/UPDATE_PAGE', newValue)
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.$store.commit('member_search/UPDATE_KEYWORD', this.searchString)
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    console.log(this.$store.getters['member_search/page']+" & "+this.$store.getters['member_search/keyword'])
    if (this.$store.getters['member_search/keyword'] != '') {
      this.searchString = this.$store.getters['member_search/keyword']
      this.rollback = true
    } else {
      this.get_data()
    }
      setTimeout(() => {
        // your code
        if (this.$store.getters['member_search/page'] > 1) {
          this.currentPage = this.$store.getters['member_search/page']
        }
      }, 1000)
    localStorage.setItem('reader_action', 'normal')
  },
  methods: {
    test() {
       localStorage.setItem('reader_action', 'update_user')
    },
    test1() {
      this.$axiosGet2(process.env.VUE_APP_API_BASE+`/members/?perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
      .then(res => {
        console.log(res)
      /*    this.items = res.members
          this.totalRows = res.total
          this.startRow = res.startRow
          this.endRow = res.endRow
          //  console.log( res.data.members.length)
        */})
    },
    one() {
      this.isAddNewMemberSidebarActive = false
      this.get_data()
    },
    get_data() {
      this.test2()
      this.$http.get(process.env.VUE_APP_API_BASE+`/members/?perpage=${this.perPage}&page=${this.currentPage}&search=${this.searchString}`)
        .then(res => {
          if (process.env.VUE_APP_VERSION != res.data.version) {
           // this.$router.go(0);
          }
          this.items = res.data.members
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          //  console.log( res.data.members.length)
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items
      console.log(items)
      this.$router.push(`/apps/member/edit/${items[0].token}/`)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
