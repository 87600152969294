<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewMemberSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);$refs.simpleRules.reset();userData=blankUserData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Member
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Display Name"
            rules="required"
          >
            <b-form-group
              label="Member Code"
              label-for="member_code"
            >
              <b-form-input
                id="member_code"
                v-model="userData.member_code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Member Code"
                class="text-uppercase"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="zh_name"
            rules="required"
          >
            <b-form-group
              label="Chinese Name"
              label-for="zh_name"
            >
              <b-form-input
                id="zh_name"
                v-model="userData.zh_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="en_name"
          >
            <b-form-group
              label="English Name"
              label-for="en_name"
            >
              <b-form-input
                id="en_name"
                v-model="userData.en_name"
                class="text-uppercase"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>







          <validation-provider
            #default="validationContext"
            name="email"
          >
            <b-form-group
              label="E-Mail"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="identity"
          >
            <b-form-group
              label="Identity Number"
              label-for="identity"
            >
              <b-form-input
                id="identity"
                v-model="userData.identity"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


            <validation-provider
            #default="validationContext"
            name="mobile"
             rules="required"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="address"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

<validation-provider
              #default="validationContext"
              name="Birth"

            >

              <b-form-group
                label="Birth"
                label-for="birth"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="userData.birth"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Gender"
            rules="required"
          >
            <b-form-group
              label="Gender"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.gender"
                :options="genderOptions"
                :clearable="false"
                input-id="gender"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
         <!-- <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.status"
                :options="statusOptions"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
-->
          <!-- Form Actions -->
          <div class="d-flex mt-2 mb-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="submit_now()"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
            <br><br><br><br><br><br><br>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMemberSidebarActive',
    event: 'update:is-add-new-Member-sidebar-active',
  },
  props: {
    isAddNewMemberSidebarActive: {
      type: Boolean,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    centerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankUserData: {
        display_name: '',
        username: '',
        password: '',
        role: 'admin',
        status: '',
      },
    }
  },
  methods: {

    resetForm() {

    },
    submit_now() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post(process.env.VUE_APP_API_BASE+'/member/add/', this.userData)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.userData = this.blankUserData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      display_name: '',
      username: '',
      password: '',
      role: 'admin',
      status: 'active',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
